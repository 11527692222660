import * as React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import InsertChartOutlinedTwoToneIcon from "@mui/icons-material/InsertChartOutlinedTwoTone";
import DifferenceIcon from "@mui/icons-material/Difference";
import GppMaybeOutlinedIcon from "@mui/icons-material/GppMaybeOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import loadable from "@loadable/component";
import { transformedData, formtPrice, setCookie } from "../../utils/functions";
import star from "../../assets/svg/star.svg";
import star1 from "../../assets/svg/star1.svg";
import "swiper/css";
import "swiper/css/pagination";
import "./index.scss";

const ChatApex = loadable(() => import("../ChartApex"));

interface ProductProps {
  product: any;
  products?: any;
  brands?: any;
  productsSettings?: any;
  compareList?: any;
  setCompareList?: any;
  openModal?: any;
  setOpenModal?: any;
}

const ShopProduct: React.FC<ProductProps> = ({
  product,
  products,
  productsSettings,
  brands,
  compareList,
  setCompareList,
  openModal,
  setOpenModal,
}) => {
  const imageGaleries = product[0]?.images?.map((e: any) => getImage(e?.src));
  const imageBrand = getImage(
    brands?.listAwards?.filter(
      (e: any) => e?.name.toLowerCase() === product[0]?.brand.toLowerCase()
    )[0]?.image
  );

  const handleCompare = () => {
    let listComparetemp = JSON.parse(compareList);
    if (listComparetemp.includes(product[0]?.slug)) {
      listComparetemp = listComparetemp.filter(
        (e: any) => e !== product[0]?.slug
      );
    } else {
      listComparetemp.push(product[0]?.slug);
      setOpenModal("compare");
    }
    if (listComparetemp.length <= 3) {
      setCompareList(JSON.stringify(listComparetemp));
      setCookie("compare", JSON.stringify(listComparetemp), 1);
    }
  };

  return (
    <section className="shop-product">
      <div className="container">
        <div className={`product-gallery fil${product?.filter}`}>
          <Swiper
            pagination={true}
            modules={[Pagination]}
            className="bike-galery"
            spaceBetween={50}
            slidesPerView={1}
          >
            {imageGaleries?.map((e: any, i: any) => {
              return (
                <SwiperSlide key={i}>
                  <GatsbyImage image={e} alt="" />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="product-details">
          {/* <GatsbyImage image={imageBrand} className="name-brand" alt="" /> */}
          <h1>{product[0]?.title}</h1>
          <div className="rating">
            <span className="start-list">
              {[1, 2, 3, 4, 5].map((e, i) => {
                return (
                  <img
                    src={product[0]?.reviews?.length > 0 ? star1 : star}
                    alt="star"
                    width="16"
                    key={i}
                  />
                );
              })}
            </span>
            <span className="rating-count">
              {`(${product[0]?.reviews?.length})`}{" "}
              {productsSettings?.reviewsTitle}
            </span>
          </div>
          <div className="price">
            {formtPrice(product[0]?.price)}{" "}
            <div className="all-taxex">
              <InsertChartOutlinedTwoToneIcon />{" "}
              {productsSettings?.priceHistory}
            </div>
          </div>
          <div className="product-features-wrap no-space">
            <div className="product-features-item">
              <div className="product-values-head">
                <img
                  src={`${process.env.PUBLIC_URL}/img/icons/electric59.svg`}
                  alt=""
                />
                <h2 className="item-title">
                  {productsSettings?.specificationsTitle}
                </h2>
              </div>
              <div className="features">
                <ul>
                  {productsSettings?.mainSpecification?.map(
                    (e: any, i: any) => (
                      <li key={i}>
                        <img
                          src={`${process.env.PUBLIC_URL}/img/icons/${e?.icon}.svg`}
                          alt=""
                        />
                        <span>
                          <span>{e?.text}</span> {product[0]?.[e?.tag]}
                        </span>
                      </li>
                    )
                  )}
                </ul>
              </div>
              <ul className="specifications">
                {productsSettings?.specifications?.map((e: any, i: any) => (
                  <li key={i}>
                    <div className="spec-list">{e?.text}</div>
                    <div className="spec-list">{product[0]?.[e?.tag]}</div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="product-more-details">
            <ul>
              <li>
                <a href={product[0]?.where_buy} target="_blank">
                  <LaunchOutlinedIcon />
                  {productsSettings?.viewOnBrand} {product[0]?.brand}
                </a>
              </li>
              <li>
                <Link to={productsSettings?.raportProblemLink}>
                  <GppMaybeOutlinedIcon />
                  {productsSettings?.raportProblem}
                </Link>
              </li>
            </ul>
          </div>
          <div className="actions">
            <div
              className={`${
                JSON.parse(compareList)?.includes(product[0]?.slug)
                  ? "button compare active"
                  : "button compare"
              }`}
              onClick={handleCompare}
            >
              <DifferenceIcon />
              {JSON.parse(compareList)?.includes(product[0]?.slug)
                ? productsSettings?.compareRemove
                : productsSettings?.compareTitle}
            </div>
            <a href={product[0]?.where_buy} target="_blank">
              <div className="button buy-from">
                {productsSettings?.advisorTitle}{" "}
                <span>{product[0]?.brand}</span>
                <LaunchOutlinedIcon />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="product-features-wrap">
          <div className="product-features-item">
            <div className="product-values-head">
              <img
                src={`${process.env.PUBLIC_URL}/img/icons/electric70.svg`}
                alt=""
              />
              <h2 className="item-title">
                {productsSettings?.comparePerformance}
              </h2>
            </div>
            <ChatApex
              type="perf"
              productsSettings={productsSettings}
              products={products}
              curent={product[0]?.slug}
            />
            <ChatApex
              type="battery"
              productsSettings={productsSettings}
              products={products}
              curent={product[0]?.slug}
            />
          </div>
          <div className="product-features-item">
            <div className="product-values-head">
              <img
                src={`${process.env.PUBLIC_URL}/img/icons/electric8.svg`}
                alt=""
              />
              <h2 className="item-title">
                {productsSettings?.priceHitoryDetails}
              </h2>
            </div>

            <ChatApex
              type="price"
              productsSettings={productsSettings}
              products={products}
              curent={product[0]?.slug}
            />
          </div>
          <div className="product-features-item">
            <div className="product-values-head">
              <img
                src={`${process.env.PUBLIC_URL}/img/icons/electric44.svg`}
                alt=""
              />
              <h2 className="item-title">
                {productsSettings?.tabReviewsTitle}
              </h2>
            </div>
            {product[0]?.reviews?.length > 0 && (
              <div className="reviews-list">
                {product[0]?.reviews?.map((e: any, i: any) => {
                  const logoReview = getImage(e?.heroImage);
                  return (
                    <div className="review-block" key={i}>
                      <GatsbyImage image={logoReview} alt={e?.alt} />
                      <p className="item-desc">{e?.content}</p>
                      <a href={e?.slug} target="_blank" rel="nofollow">
                        {productsSettings?.tabReviewsRedFull}
                      </a>
                    </div>
                  );
                })}
              </div>
            )}
            <p className="review-text">
              {productsSettings?.tabNoReviewsMessage}
              <Link to={productsSettings?.raportProblemLink}>
                {productsSettings?.tabReviewsSubmit}
              </Link>
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="product-details ">
          <div className="actions">
            <a href={product[0]?.where_buy} target="_blank">
              <div className="button buy-from">
                {productsSettings?.advisorTitle}{" "}
                <span>{product[0]?.brand}</span>
                <LaunchOutlinedIcon />
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShopProduct;
